<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";

export type PageHeaderColor = "default" | "red";
export type PageHeaderVariant = "default" | "row";

type Props = {
    color?: PageHeaderColor;
    variant?: PageHeaderVariant;
};
const { color = "default", variant = "default" } = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "flex gap-1 items-start p-8 lg:py-10 lg:px-16 m-4 rounded",
    variants: {
        variant: {
            default: "flex-col",
            row: "flex-row items-center",
        },
        color: {
            default: "bg-slate-50",
            red: "bg-slate-100 bg-gradient-to-br from-slate-200",
        },
    },
};
const { aClass } = installAntlerComponent(
    "page-header",
    { color, variant },
    classes,
);
</script>

<template>
    <div :class="aClass()">
        <slot />

        <AppButtonGroup v-if="$slots.buttons" class="mt-4">
            <slot name="buttons" />
        </AppButtonGroup>
    </div>
</template>
